<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-02 14:36:03
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-02 16:28:11
 * @FilePath: /yiyun_project/src/views/shortVideo/taskManage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-main>
    <div class="btnBox">
      <el-button size="mini" @click="addTaskDialog" type="primary">+ 添加任务</el-button>
    </div>
    <el-table :data="dataInfo.list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="task_name" label="任务名称" align="center"></el-table-column>
      <el-table-column prop="task_desc" label="任务描述" align="center"></el-table-column>
      <el-table-column prop="cycle_num" label="任务次数" align="center">
        <template v-slot="{ row }">
          {{ row.cycle_type == 2 ? `每日可完成${row.cycle_num}次` : `${row.cycle_num}次` }}
        </template>
      </el-table-column>
      <el-table-column prop="integral" label="每次赠送积分" align="center"></el-table-column>
      <el-table-column label="是否为每日任务" align="center">
        <template v-slot="{ row }">
          <el-switch disabled v-model="row.cycle_type" :active-value="2" :inactive-value="1"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="发布时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="editRow(row)">编辑</el-button>
          <el-button type="text" @click="deleteTask(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="dataInfo.total_number" :page="dataInfo.page" :pageNum="dataInfo.rows" @updatePageNum="updateData"></Paging>
    <!-- 操作弹窗 -->
    <el-dialog :title="dialogInfo.id ? '编辑任务' : '添加任务'" :visible.sync="showDialog" width="600rpx">
      <el-form label-width="120px" class="demo-ruleForm">
        <el-form-item label="任务名称">
          <el-input style="width: 220px" v-model="dialogInfo.task_name" placeholder="请输入任务名称"></el-input>
        </el-form-item>
        <el-form-item label="任务描述">
          <el-input style="width: 220px" type="textarea" :rows="2" v-model="dialogInfo.task_desc" placeholder="请输入任务描述"></el-input>
        </el-form-item>
        <el-form-item label="赠送积分">
          <el-input style="width: 220px" type="number" v-model="dialogInfo.integral" placeholder="请输入赠送积分"></el-input>
        </el-form-item>
        <el-form-item label="任务类型：">
          <el-select v-model="dialogInfo.type" placeholder="请选择" @change="changeType">
            <el-option label="新用户注册 赠送 " :value="1"></el-option>
            <el-option label="绑定微信昵称" :value="2"></el-option>
            <el-option label="分享好友获取积分" :value="3"></el-option>
            <el-option label="看视频送积分" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="每日任务">
          <el-switch :disabled="dialogInfo.type == 2 || dialogInfo.type == 1 ? true : false" v-model="dialogInfo.cycle_type" :active-value="2" :inactive-value="1"></el-switch>
        </el-form-item>
        <el-form-item label="任务次数">
          <el-input style="width: 220px" type="number" v-model="dialogInfo.cycle_num" placeholder="请输入任务次数"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="sureGrant">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>
<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      dataInfo: {
        page: 1,
        rows: 10,
        list: [],
        total_number: 0,
      },
      showDialog: false,
      dialogInfo: {
        task_name: '',
        task_desc: '',
        integral: '',
        type: '',
        cycle_num: 1,
        cycle_type: 1,
      },
    };
  },
  created() {
    this.getTaskList();
  },
  methods: {
    sureGrant() {
      let flag = true;
      for (const key in this.dialogInfo) {
        if (!this.dialogInfo[key]) {
          console.log(this.dialogInfo[key], key);
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.$message.warning('请填写合规数据');
        return;
      }
      let url = this.$api.shortVideo.integralAdd;
      let obj = _.cloneDeep(this.dialogInfo);
      let str = '添加成功';
      if (obj.id) {
        url = this.$api.shortVideo.integralEdit;
        str = '编辑成功';
      }
      this.$axios.post(url, obj).then(res => {
        if (res.code == 0) {
          this.$message.success(str);
          this.getTaskList();
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    editRow(data) {
      this.dialogInfo.id = null;
      for (const key in this.dialogInfo) {
        this.dialogInfo[key] = data[key];
      }
      this.showDialog = true;
    },
    addTaskDialog() {
      this.dialogInfo = {
        task_name: '',
        task_desc: '',
        integral: '',
        type: '',
        cycle_num: 1,
        cycle_type: 1,
      };
      this.showDialog = true;
    },
    updateData(val, status) {
      if (status == 0) {
        this.dataInfo.rows = val;
      } else {
        this.dataInfo.page = val;
      }
      this.getTaskList();
    },
    getTaskList(style) {
      if (style) this.dataInfo.page = 1;
      let obj = {
        page: this.dataInfo.page,
        rows: this.dataInfo.rows,
      };
      this.$axios.post(this.$api.shortVideo.integralList, obj).then(res => {
        if (res.code == 0) {
          this.dataInfo.list = res.result.list;
          this.dataInfo.total_number = res.result.total_number;
          this.showDialog = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    deleteTask(data) {
      this.$confirm('此操作将删除该任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.shortVideo.integralDelete, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('操作成功');
            this.getTaskList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    changeType() {
      if (this.dialogInfo.type == 2 || this.dialogInfo.type == 1) {
        this.dialogInfo.cycle_type = 1;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .btnBox {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
}
</style>
